<template>
  <div>
    <parcelamento-base
      :filtros-rapidos-custom="filtrosRapidos"
    />
  </div>
</template>

<script>
export default {
  components: {
    ParcelamentoBase: () => import('../ParcelamentoView.vue'),
  },
  data() {
    return {
      filtrosRapidos: [
        {
          id: 'EmParcelamentoAguardando',
          colunas: ['EmParcelamentoAguardando'],
          label: 'Em parcelamento',
          valor: '1',
        },
        {
          id: 'StatusEmAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Possui Parcelas em Atraso',
          valor: '2',
        },
        {
          id: 'StatusSemAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Não Possui Parcelas em Atraso',
          valor: '1',
        },
        {
          id: 'MesPaga',
          colunas: ['MesPaga'],
          label: 'Parcela do Mês Paga',
          valor: '1',
        },
        {
          id: 'MesNaoPaga',
          colunas: ['MesNaoPaga'],
          label: 'Parcela do Mês não Paga',
          valor: '2',
        },
        {
          id: 'AguardandoConcluir',
          colunas: ['Situacao'],
          label: 'Parcelamento Aguardando Concluir',
          valor: '3',
        }],
    }
  },

}
</script>

<style>

</style>
